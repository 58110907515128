
.new-edit-container {
    margin:20px 0 0 50px;
}

.edit-label {
    margin-right:20px;
    padding-top: 20px;
}

.field-container {
    margin:10px 0 0 10px;
}

.bottom-container {
    margin:20px;
    padding: 20px;
    border:1px dotted black;
}