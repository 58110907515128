
.url-container {
    color: #007bff;
  }
  
  .url-container:visited {
    color: red;
  }
  
  .url-container:hover {
    text-decoration: underline;
    color:#0056b3;
  }
  
  .url-container:active {
    color: hotpink;
  } 
