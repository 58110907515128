.screen-grab {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: #ffd51e;
}

.cross-btn {
  height: 10vh;
  display: flex;
  justify-content: flex-end;
}

.content-screen {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button {
  background-color: #ffd51e;
  border: none;
}

.content-screen a {
  background: #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #fff;
  padding: 20px 30px;
}
